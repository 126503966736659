import './App.css';
import React, {useState} from 'react';
import YoutubeEmbed from "./YoutubeEmbed";
import EvoEmbed from './EvoEmbed';
import axios from 'axios';
import EvorideVids from './EvorideVids';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logowhite from './logowhite.png';
import esm from './esm.png';
import cyr2 from './cyr2.png';
import tls2 from './tls2.png';
import appweb2 from './appweb2.png';
import appweb3 from './appweb3.png';
import appweb4 from './appweb4.png';
import appweb5 from './appweb5.png';
import rangemob from './rangemob.png';
import evsmob from './evsmob.png';
import ban1mob from './ban1mob.jpg';
import ban2mob from './ban2mob.jpg';
import bagspace from './bagspace.jpg';
import ban1 from './ban1.jpg';
import ban2 from './ban2.png';
import camV from './camV.png';
import case1 from './case1.jpg';
import ec1 from './ec1.jpg';
import ec2 from './ec2.jpg';
import ev from './ev.jpg';
import evoallrounder from './evoallrounder.jpg';
import fsa from './fsa.jpg';
import openback from './openback.jpg';
import garage1 from './garage1.jpg';
import garage2 from './garage2.jpg';
import garage3 from './garage3.jpg';
import key from './key.jpg';
import key1 from './key1.jpg';
import key3 from './key3.jpg';
import rearview from './rearview.png';
import roadway from './roadway.jpg';
import scoot1 from './scoot1.png';
import scoot2 from './scoot2.png';
import scoot3 from './scoot3.png';
import ssvw from './ssvw.png';
import us from './us.png';
import vlcs from './vlcs.jpg';
import sh from './sh.png';
import TwinLogo from './TwinLogo.png';
import { text } from '@fortawesome/fontawesome-svg-core';


function App() {
  const[formData, setFormData] = useState({
    name: '',
    purpose: '',
    email: '',
    contact: '',
    message: ''
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form data is sent successfully', formData);
    // Add code here to submit formData to a backend or API
    axios.post('https://evoridebackend-26dy.vercel.app/send', formData)
  .then(res => {
    // console.log(res.data.message);
    setFormSubmitted('Form submitted successfully');
    setTimeout(()=>{
      window.location.reload();
    },1000);
  })
  .catch(err => {
    console.log(err);
  });
  setFormSubmitted(true);
  };
  return (
      <div>
        <nav className="navbar navbar-expand-lg sticky-top bg-dark">
          <div className="container-fluid">
            <a className="navbar-brand" href="/"><img className="logo-web" src={tls2} alt='logo' /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#APPROACH"onClick={() => {
    document.querySelector('#navbarSupportedContent').classList.remove('show');
  }}>APPROACH</a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#PRODUCT" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    PRODUCT
                  </a>
                  <ul className="dropdown-menu bg-dark">
                    <li><a className="dropdown-item" href="#EVOSCOOT"onClick={() => {
    document.querySelector('#navbarSupportedContent').classList.remove('show');
  }}>EVOSCOOT</a></li>
                    <li><a className="dropdown-item" href="#EVOSWAP"onClick={() => {
    document.querySelector('#navbarSupportedContent').classList.remove('show');
  }}>EVOSWAP</a></li>
                    <li><a className="dropdown-item" href="#EVODRIVE"onClick={() => {
    document.querySelector('#navbarSupportedContent').classList.remove('show');
  }}>EVODRIVE</a></li>
                    <li><a className="dropdown-item" href="#EVOCHARGE"onClick={() => {
    document.querySelector('#navbarSupportedContent').classList.remove('show');
  }}>EVOCHARGE</a></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#ECOSYSTEM"onClick={() => {
    document.querySelector('#navbarSupportedContent').classList.remove('show');
  }}>ECOSYSTEM</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#VISION"onClick={() => {
    document.querySelector('#navbarSupportedContent').classList.remove('show');
  }}>VISION</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#OURSTORY"onClick={() => {
    document.querySelector('#navbarSupportedContent').classList.remove('show');
  }}>OUR STORY</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#CAREERS"onClick={() => {
    document.querySelector('#navbarSupportedContent').classList.remove('show');
  }}>CAREERS</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#REACHOUT"onClick={() => {
    document.querySelector('#navbarSupportedContent').classList.remove('show');
  }}>REACH OUT</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className='container-fluid intro' style={{backgroundImage: 'url('+ban1+')',backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
         <div className='row'>
            <div className='col-md-5 col-sm-6 intro-box'>
              <div className='ban1-box'>
                <h2>Safe<br></br>Affordable<br></br>Sustainable<br></br>Solutions to Fulfil the Evolving needs of Modern India and the World.</h2>
                <p>For Personal mobility to the Gig community, Our solutions cater to everyone.</p>
              </div>
            </div>
            <div className='col-md-7 col-sm-6'>
              
            </div>
          </div>
        </div>
        <div className='container-fluid intro-mobile' style={{backgroundImage: 'url('+ban1mob+')',backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>  
          <div className='row'>
            <div className='col-md-5 col-sm-6 intro-box'>
              <div className='ban1-box'>
                <h2>Safe<br></br>Affordable<br></br>Sustainable<br></br>Solutions to Fulfil the Evolving needs of Modern India and the World.</h2>
                <p>For Personal mobility to the Gig community, Our solutions cater to everyone.</p>
              </div>
            </div>
            <div className='col-md-7 col-sm-6'>
              
            </div>
          </div>
        </div>
        <div className='container-fluid intro2' style={{backgroundImage: 'url('+ban2+')',backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
          <div className='row'>
            <div className='col-md-7 col-sm-6'>
              
            </div>
            <div className='col-md-5 col-sm-6 intro2-box'>
              <div className='ban2-box'>
                <h2>A versatile solution<br></br>for your varying needs</h2>
                <p>A mobility solution for every purpose and situation<br></br>PERSONAL OR FLEET</p>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid intro2-mobile' style={{backgroundImage: 'url('+ban2mob+')',backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
          <div className='row'>
            <div className='col-md-7 col-sm-6'>
              
            </div>
            <div className='col-md-5 col-sm-6 intro2-box'>
              <div className='ban2-box'>
                <h2>A versatile solution<br></br>for your varying needs</h2>
                <p>A mobility solution for every purpose and situation<br></br>PERSONAL OR FLEET</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid pro-scoot" id='APPROACH'>
          <div className="product">
            <div className="row">
              <div className="col-md-6 col-sm-6 proScoot">
              <h1 className="pro-head-mobile">A PLATFORM OF <span>OPPORTUNITY</span>.</h1>
              <div className='evideo'>
                <EvoEmbed embedId="ae7GX0_C2Wg"/>
              </div>
                {/* <EvoEmbed embedId="ae7GX0_C2Wg"/> */}
                {/* <img className="esm-image" src={esm} alt='shared-mobility'/> */}
              </div>
              <div className="col-md-6 col-sm-6 proScootNext">
                <h1 className="pro-head">A PLATFORM OF <span>OPPORTUNITY</span>.</h1>
                <div className="pro-box">
                  <h2>A Fully Modular 3Part Chassis Construction</h2>
                  <h3>Designed For</h3>
                  <ul>
                    <li>
                      Robust yet Adaptable Performance.
                    </li>
                    <li>
                      Multiple Baggage/Lugagge Options.
                    </li>
                    <li>
                      Multiple Users/Riders.
                    </li>
                    <li>
                      Micro Industry Manufacturing
                    </li>
                    <li>
                      Inventory Lite Manufacturing
                    </li>
                    <li>
                      Industry 4.0 Ready
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid ssvw-scoot' id='PRODUCT'>
          <div className='row'>
            <div className='col-md-6 col-sm-6 ssvw-scootnext' id='EVOSCOOT'>
              <div className='ssvw-box-head'>
                <h1>INTRODUCING - <span>EVO</span>SCOOT®</h1>
                <h3>THE 21ST CENTURY <span>SCOOTER</span></h3>
              </div>
              <div className='ssvw-image-mobile'>
              <img src={ssvw} alt='side-view'/>
              </div>
              <div className='ssvw-box'>
                <h2>The EvoScooT® is our first product.
                  It's a Factory to Fleet, Plug and Play Electric Scooter designed to be
                  perfectly suited for shared mobility, logistics, delivery fleet and personal requirements.</h2>
              </div>
              <div className='ssvw-image'>
              <img src={ssvw} alt='side-view'/>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 evids'>
              
              <EvoEmbed embedId="eemmWj3k8_Q" />
              
            </div>
          </div>
        </div>
        <div className='container-fluid evorange' id='section3'>
          <div className='row'>
            <div className='col-md-7 col-sm-6 EVR'>
              <div className='evo-range-head'>
                <h1>Choose your <span>Range</span></h1>
                <h3>80 km per pack/charge/battery swap on the EvoScoot upto 230 km of range*</h3>
              </div>
              <div className='row'>
                <div className='col-md-4 col-sm-6 EVR'>
                  <div className='evo-range-specs'>
                    <h1>1Pack - <span>80 km</span></h1>
                    <h1>2Pack - <span>150 km</span></h1>
                    <h1>3Pack - <span>230 km</span></h1>
                  </div>
                </div>
                <div className='col-md-8 col-sm-6 EVR'>
                  <div className='evo-range-first-image'>
                    <img src={scoot1} alt='scoot1'/>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 col-sm-6 evi'>
                  <div className='evo-range-second-image'>
                    <img src={scoot2} alt='scoot2'/>
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 evi'>
                  <div className='evo-range-third-image'>
                    <img src={scoot3} alt='scoot3'/>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-5 col-sm-6 EVR2'>
              <div className='row'>
                <div className='col-md-6 col-sm-6 bagspace'>
                  <div className='evo-range-fourth-image'>
                    <img src={openback} alt='ssvw'/>
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 bagspace'>
                  <div className='evo-range-fifth-image'>
                    <img src={bagspace} alt='ssvw'/>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 EVRM'>
                <div className='range-image-mobile'>
                  <img src={cyr2} alt='rangemobile'/>
                </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-sm-6 last evrb'>
              <div className='evo-range-box'>
                <h2>There are no limits on where these swap stations can be located thanks to their compact form factor and optimized grid 
                  charging capabilities.</h2>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 evrb'>
              <div className='evo-range-sixth-image'>
                <img src={sh} alt='ssvw'/>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid evosmart'>
          <div className='row'>
            <div className='col-md-5 col-sm-6 evs'>
              <div className='evo-smart-head-mobile'>
                  <h1>CONNECTED SMART <span>SCOOTER</span></h1>
                  <h3>Know what is happening with your scooters and swap stations in real time.
                    Not just that, we also offer the full back-end solution for all B2B requirements.</h3>
                </div>
              <div className='evo-smart-image'>
                <img src={appweb2} alt='app2'/>
              </div>
            </div>
            <div className='col-md-7 col-sm-6 evsa'>
              <div className='evo-smart-head'>
                <h1>CONNECTED SMART <span>SCOOTER</span></h1>
                <h3>Know what is happening with your scooters and swap stations in real time.
                  Not just that, we also offer the full back-end solution for all B2B requirements.</h3>
              </div>
              <div className='row'>
                <div className='col-md-6 col-sm-6 evsa1'>
                  <div className='evo-smart-app-image'>
                   <img src={appweb3} alt='app3'/>
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 evsa2'>
                  <div className='evo-smart-app-first-image'>
                   <img src={appweb5} alt='app5'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="video">
          {/* <h1>Youtube Embed</h1> */}
          <YoutubeEmbed embedId="dZBGw1eoIhc" />
        </div>
        <div className='container-fluid evoswap' id='EVOSWAP'>
          <div className='row'>
            <div className='col-md-7 col-sm-6 evswh'>
              <div className='evoswap-head'>
                <h1><span>EVO</span>SWAP™</h1>
                <h3>Our Swappable battery pack architecture, redefines <span>MODULARITY</span>.</h3>
              </div>
              <div className='evoswap-image'>
                <img src={key} alt='key'/>
              </div>
              {/* <div className='evoswap-image-mobile'>
                <img src={evsmob} alt='key'/>
              </div> */}
              <div className='evoswap-sub-head'>
                <h3>SAFE <span>-</span> FAST <span>-</span> EASY <span>-</span> LIGHT <span>-</span> RUGGED <span>-</span> COMPACT</h3>
              </div>
            </div>
            <div className='col-md-5 col-sm-6 evsp'>
              <div className='row'>
                <div className='col-md-6 col-sm-6 evsp1'>
                  <div className='evoswap-first-image'>
                    <img src={vlcs} alt='esm'/>
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 evsp2'>
                  <div className='evoswap-second-image'>
                    <img src={case1} alt='case'/>
                  </div>
                </div>
              </div>
              <div className='evoswap-box'>
                <h2>Designed to be <span>Everywhere:</span></h2>
                <ul>
                  <li>
                    In the Living Room
                  </li>
                  <li>
                    At the Office Desk
                  </li>
                  <li>
                    At the Apartment Parking
                  </li>
                  <li>
                    At your Supermarket
                  </li>
                  <li>
                    At Charging hubs
                  </li>
                  <li>
                    At Mom&Pop/Kirana stores
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid evocharge' id='EVOCHARGE'>
          <div className='row'>
            <div className='col-md-5 col-sm-6 evch'>
              <div className='evocharge-head-mobile'>
                  <h1><span>EVO</span>CHARGE™</h1>
                  <h3>Fully Weld-Less battery system. First of its kind <span>Tab</span>-Cooling for superior thermal <span>stability</span> High <span>Performance</span> in extreme temperatures.</h3>
                </div>
                <div className='evocharge-image-mobile'>
                  <img src={key1} alt='key'/>
                </div>
                <div className='evocharge-sub-head-mobile'>
                  <h3>SAFE <span>-</span> FAST <span>-</span> EASY <span>-</span> LIGHT <span>-</span> RUGGED <span>-</span> COMPACT</h3>
                </div>
              <div className='row'>
                <div className='col-md-6 col-sm-6 evch1'>
                  <div className='evocharge-first-image'>
                    <img src={ec1} alt='esm'/>
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 evch2'>
                  <div className='evocharge-second-image'>
                    <img src={ec2} alt='case'/>
                  </div>
                </div>
              </div>
              <div className='evocharge-box'>
                <h2>Engineered to be <span>Everywhere:</span></h2>
                <ul>
                  <li>
                    Multi Level Inbuilt Safety
                  </li>
                  <li>
                    High Energy Density
                  </li>
                  <li>
                    Versitile and Scalable platform
                  </li>
                  <li>
                    Industry first Cell Serviceability
                  </li>
                  <li>
                    Extream Temperature & NVH
                  </li>
                  <li>
                    2nd Life Re-useability
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-7 col-sm-6 evoChargeNext'>
              <div className='evocharge-head'>
                <h1><span>EVO</span>CHARGE™</h1>
                <h3>Fully Weld-Less battery system. First of its kind <span>Tab</span>-Cooling for superior thermal <span>stability</span> High <span>Performance</span> in extreme temperatures.</h3>
              </div>
              <div className='evocharge-image'>
                <img src={key1} alt='key'/>
              </div>
              <div className='evocharge-sub-head'>
                <h3>SAFE <span>-</span> FAST <span>-</span> EASY <span>-</span> LIGHT <span>-</span> RUGGED <span>-</span> COMPACT</h3>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid evodrive' id='EVODRIVE'>
          <div className='row'>
            <div className='col-md-7 col-sm-6 evdrNext'>
              <div className='evodrive-head'>
                <h1><span>EVO</span>DRIVE™</h1>
                <h3>High Torque & Acceleration delivery, Seamless Shifting technology Redefines <span>Efficiency</span> and <span>Performance</span>.</h3>
              </div>
              <div className='evodrive-image'>
                <img src={key3} alt='key'/>
              </div>
              <div className='evodrive-sub-head'>
                <h3>SAFE <span>-</span> FAST <span>-</span> EASY <span>-</span> LIGHT <span>-</span> RUGGED <span>-</span> COMPACT</h3>
              </div>
            </div>
            <div className='col-md-5 col-sm-6 evdr'>
              <div className='row'>
                <div className='col-md-6 col-sm-6 evdr1'>
                  <div className='evodrive-first-image'>
                    <img src={fsa} alt='esm'/>
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 evdr2'>
                  <div className='evodrive-second-image'>
                    <img src={rearview} alt='case'/>
                  </div>
                </div>
              </div>
              <div className='evodrive-box'>
                <h2>Designed to be <span>Everywhere:</span></h2>
                <ul>
                  <li>
                    Torque & Speed on demand
                  </li>
                  <li>
                    Seamless Gear Changes
                  </li>
                  <li>
                    Optimized battery utilization
                  </li>
                  <li>
                    Extremely low maintainance
                  </li>
                  <li>
                    Moduler and Variable ratios
                  </li>
                  <li>
                    Supporting existing supply chain
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='evo-allrounder-head' id='ECOSYSTEM'>
          <h1>AN <span>ECO</span>SYSTEM OF EM<span>POWER</span>MENT.</h1>
        </div>
        <div className='container-fluid evo-allrounder' >
          <img src={evoallrounder} alt='allrounderimage'/>
        </div>
        <div className='container-fluid vision' id='VISION' style={{backgroundImage: 'url('+camV+')',height:'120vh',backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
          <div className='row'>
            <div className='col-md-7 col-sm-6'>

            </div>
            <div className='col-md-5 col-sm-6'>
              <div className='vision-head'>
                <h1>VISION</h1>
              </div>
              <div className='vision-box'>
                <h2>Evoride stands for a revolution in personal and shared mobility.<br/><br/> We believe in leveraging technology and engineering first principles to be able to make a better tomorrow. An ecosystem that can self-sustain, yet be flexible.<br/> The customer first philosophy leads to benchmark setting products and services.</h2>
              </div>
            </div>
          </div>
        </div> 
         <div className='container-fluid evo-story' id='OURSTORY'>
          <div className='row'>
            <div className='col-md-6 col-sm-6 evoStoryImage'>
              <div className='evo-story-head-mobile'>
                  <h1>OUR <span>STORY</span></h1>
                  <h3>ONE WORD - <span>PASSION</span>.</h3>
              </div>
              <div className='evo-story-image'>
                <img src={us} alt='us'/>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 evoStory'>
              <div className='evo-story-head'>
                <h1>OUR <span>STORY</span></h1>
                <h3>ONE WORD - <span>PASSION</span>.</h3>
              </div>
              <div className='evo-story-box'>
                <h2>We are convinced and committed towards making Electric Vehicles, the today and tomorrow of mobility. Passion ensures attention to detail, it is the driving force behind what we're doing. We're passionate and committed to impact people's lives everyday, consistently.</h2>
              </div>
            </div>
          </div>
        </div> 
         <div className='container-fluid evo-careers' id='CAREERS'>
          <div className='row'>
            <div className='col-md-6 col-sm-6 evc'>
              <div className='evo-careers-head-mobile'>
                <h1><span>CAR</span>EERS</h1>
                <h3>FREEDOM OF THOUGHT</h3>
              </div>
              <div className='evo-careers-image'>
                <img src={garage1} alt='garage1'/>
                <img src={garage3} alt='garage3'/>
                <img src={garage2} alt='garage2'/>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 evc1'>
              <div className='evo-careers-head'>
                <h1><span>CAR</span>EERS</h1>
                <h3>FREEDOM OF THOUGHT</h3>
              </div>
              <div className='evo-careers-box'>
                <h2>We are passionate automotive enthusiasts with Hands-on experience, who can implement real world future technology in the present, with the most commercially viable methods is our mantra. Thus every detail, every thought process and every idea has to be the most optimised version of itself for the intended purpose. To achieve this, we would want to go to any extent and ensure that it would meet our high expectations and standards. To set benchmarks. Break away from the mould.<br/><br/>If you think you align to our approach/vision to build solutions to re-calibrate the industry, reach out to us!</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid reach-out'id='REACHOUT' style={{backgroundImage: 'url('+roadway+')',backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
          <div className='row'>
            <div className='col-md-6 col-sm-6'>
              <div className='reachout-head'>
                <h1>REACHOUT</h1>
              </div>
              <div className='reachout-form-box'>
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label className='form-label' htmlFor='name'>Name</label>
                    <input
                    type='text'
                    className= 'form-control'
                    id= 'name'
                    name= 'name'
                    value={formData.name}
                    onChange={handleChange}
                    required
                    />
                  </div>
                  <div className='form-group'>
                    <label className='form-label' htmlFor='purpose'>Purpose</label>
                    <input
                    type='text'
                    className= 'form-control'
                    id= 'purpose'
                    name= 'purpose'
                    value={formData.purpose}
                    onChange={handleChange}
                    required
                    />
                  </div>
                  <div className='form-group'>
                    <label className='form-label' htmlFor='email'>Email</label>
                    <input
                    type='email'
                    className= 'form-control'
                    id= 'email'
                    name= 'email'
                    value={formData.email}
                    onChange={handleChange}
                    required
                    />
                  </div>
                  <div className='form-group'>
                    <label className='form-label' htmlFor='contact'>Contact Number</label>
                    <input
                    type='number'
                    className= 'form-control'
                    id= 'contact'
                    name= 'contact'
                    value={formData.contact}
                    onChange={handleChange}
                    required
                    />
                  </div>
                  <div className='form-group'>
                    <label className='form-label' htmlFor='message'>Message</label>
                    <textarea
                    type='text'
                    className= 'form-control'
                    id= 'message'
                    name= 'message'
                    rows='4'
                    // cols='
                    value={formData.message}
                    onChange={handleChange}
                    required
                    />
                  </div>
                  <button type='submit' className='btn btn-primary'>Submit</button>
                </form>
              </div>
            </div>
            <div className='col-md-5 col-sm-6'>
              
            </div>
          </div>
        </div>
        <div className='container-fluid footer-background'style={{backgroundImage: 'url('+ev+')',backgroundPosition:'center',backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>

        </div>
         <div className='container-fluid footer'>
          {/* <div className=' footer-box'> */}
            <div className='row'>
              <div className='col-md-4 col-sm-4 mail'>
                <div className='mail-evoride'>
                  <a href='/'><FontAwesomeIcon icon={faEnvelope} />
                  </a>
                  <h4>info@evoride.in</h4>
                </div>
              </div>
              <div className='col-md-4 col-sm-4 fl'>
                <img className='logo-footer' src={logowhite} alt='footerLogo'/>
              </div>
              <div className='col-md-4 col-sm-4 social'>
                <div className='social-text'>
                  <h4>"FOLLOW" <span>US</span></h4>
                  <a href='https://www.youtube.com/@EVORIDE'>
                  <FontAwesomeIcon icon={faYoutube} />
                  </a>
                  <a href='https://www.instagram.com/evoridemotors/'>
                  <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a href='https://www.linkedin.com/company/evoridemotors/mycompany/?viewAsMember=true'>
                  <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </div>
                <h4>© EVORIDE MOTORS PRIVATE LIMITED 2022</h4>
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>
  );
}

export default App;