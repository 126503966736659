import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "fontsource-cooper-hewitt";
import "fontsource-montserrat";
import reportWebVitals from './reportWebVitals';
// import './fonts/CooperHewitt-Bold.otf';
// import './fonts/CooperHewitt-BoldItalic.otf';
// import './fonts/CooperHewitt-Book.otf';
// import './fonts/CooperHewitt-BookItalic.otf';
// import './fonts/CooperHewitt-Heavy.otf';
// import './fonts/CooperHewitt-HeavyItalic.otf';
// import './fonts/CooperHewitt-Light.otf';
// import './fonts/CooperHewitt-LightItalic.otf';
// import './fonts/CooperHewitt-Medium.otf';
// import './fonts/CooperHewitt-MediumItalic.otf';
// import './fonts/CooperHewitt-Semibold.otf';
// import './fonts/CooperHewitt-SemiboldItalic.otf';
// import './fonts/CooperHewitt-Thin.otf';
// import './fonts/CooperHewitt-ThinItalic.otf';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
